/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanBaseResponse } from '../models/boolean-base-response';
import { OrderDirection } from '../models/order-direction';
import { PropertyFilter } from '../models/property-filter';
import { PurchaseOrderAssetInfoDtoPagedResponseBaseResponse } from '../models/purchase-order-asset-info-dto-paged-response-base-response';
import { PurchaseOrderBuilderProjectDtoListBaseResponse } from '../models/purchase-order-builder-project-dto-list-base-response';
import { PurchaseOrderDto } from '../models/purchase-order-dto';
import { PurchaseOrderDtoBaseResponse } from '../models/purchase-order-dto-base-response';
import { PurchaseOrderDtoListBaseResponse } from '../models/purchase-order-dto-list-base-response';
import { PurchaseOrderItemDtoListBaseResponse } from '../models/purchase-order-item-dto-list-base-response';
import { PurchaseOrderListDtoPagedResponseBaseResponse } from '../models/purchase-order-list-dto-paged-response-base-response';
import { PurchaseOrderPaymentDtoListBaseResponse } from '../models/purchase-order-payment-dto-list-base-response';
import { PurchaseOrderStatus } from '../models/purchase-order-status';

@Injectable({
  providedIn: 'root',
})
export class PurchaseOrderService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiPurchaseOrderGetAllGet
   */
  static readonly ApiPurchaseOrderGetAllGetPath = '/api/PurchaseOrder/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetAllGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetAllGet$Plain$Response(params?: {
    productName?: string;
  }): Observable<StrictHttpResponse<PurchaseOrderDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetAllGetPath, 'get');
    if (params) {
      rb.query('productName', params.productName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetAllGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetAllGet$Plain(params?: {
    productName?: string;
  }): Observable<PurchaseOrderDtoListBaseResponse> {

    return this.apiPurchaseOrderGetAllGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoListBaseResponse>) => r.body as PurchaseOrderDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetAllGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetAllGet$Json$Response(params?: {
    productName?: string;
  }): Observable<StrictHttpResponse<PurchaseOrderDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetAllGetPath, 'get');
    if (params) {
      rb.query('productName', params.productName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetAllGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetAllGet$Json(params?: {
    productName?: string;
  }): Observable<PurchaseOrderDtoListBaseResponse> {

    return this.apiPurchaseOrderGetAllGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoListBaseResponse>) => r.body as PurchaseOrderDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderPaginatedListGet
   */
  static readonly ApiPurchaseOrderPaginatedListGetPath = '/api/PurchaseOrder/paginated-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderPaginatedListGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedListGet$Plain$Response(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderPaginatedListGetPath, 'get');
    if (params) {
      rb.query('CompanyName', params.CompanyName, {});
      rb.query('ProductName', params.ProductName, {});
      rb.query('Status', params.Status, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderPaginatedListGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedListGet$Plain(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PurchaseOrderListDtoPagedResponseBaseResponse> {

    return this.apiPurchaseOrderPaginatedListGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>) => r.body as PurchaseOrderListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderPaginatedListGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedListGet$Json$Response(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderPaginatedListGetPath, 'get');
    if (params) {
      rb.query('CompanyName', params.CompanyName, {});
      rb.query('ProductName', params.ProductName, {});
      rb.query('Status', params.Status, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderPaginatedListGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedListGet$Json(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PurchaseOrderListDtoPagedResponseBaseResponse> {

    return this.apiPurchaseOrderPaginatedListGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderListDtoPagedResponseBaseResponse>) => r.body as PurchaseOrderListDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderPaginatedAssetInfoGet
   */
  static readonly ApiPurchaseOrderPaginatedAssetInfoGetPath = '/api/PurchaseOrder/paginated-asset-info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderPaginatedAssetInfoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedAssetInfoGet$Plain$Response(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderPaginatedAssetInfoGetPath, 'get');
    if (params) {
      rb.query('CompanyName', params.CompanyName, {});
      rb.query('ProductName', params.ProductName, {});
      rb.query('Status', params.Status, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderPaginatedAssetInfoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedAssetInfoGet$Plain(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse> {

    return this.apiPurchaseOrderPaginatedAssetInfoGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>) => r.body as PurchaseOrderAssetInfoDtoPagedResponseBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderPaginatedAssetInfoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedAssetInfoGet$Json$Response(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderPaginatedAssetInfoGetPath, 'get');
    if (params) {
      rb.query('CompanyName', params.CompanyName, {});
      rb.query('ProductName', params.ProductName, {});
      rb.query('Status', params.Status, {});
      rb.query('PageIndex', params.PageIndex, {});
      rb.query('PageSize', params.PageSize, {});
      rb.query('GetAll', params.GetAll, {});
      rb.query('Archived', params.Archived, {});
      rb.query('SearchTerm', params.SearchTerm, {});
      rb.query('PropertyFilters', params.PropertyFilters, {});
      rb.query('OrderProperty', params.OrderProperty, {});
      rb.query('Order', params.Order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderPaginatedAssetInfoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderPaginatedAssetInfoGet$Json(params?: {
    CompanyName?: string;
    ProductName?: string;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    Status?: PurchaseOrderStatus;
    PageIndex?: number;
    PageSize?: number;
    GetAll?: boolean;
    Archived?: boolean;
    SearchTerm?: string;
    PropertyFilters?: Array<PropertyFilter>;
    OrderProperty?: string;

    /**
     * 0 &#x3D; Ascending
     * 
     * 1 &#x3D; Descending
     */
    Order?: OrderDirection;
  }): Observable<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse> {

    return this.apiPurchaseOrderPaginatedAssetInfoGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderAssetInfoDtoPagedResponseBaseResponse>) => r.body as PurchaseOrderAssetInfoDtoPagedResponseBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderGetPurchaseOrderPost
   */
  static readonly ApiPurchaseOrderGetPurchaseOrderPostPath = '/api/PurchaseOrder/get-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetPurchaseOrderPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPurchaseOrderPost$Plain$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetPurchaseOrderPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetPurchaseOrderPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPurchaseOrderPost$Plain(params?: {
    id?: number;
  }): Observable<PurchaseOrderDtoBaseResponse> {

    return this.apiPurchaseOrderGetPurchaseOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoBaseResponse>) => r.body as PurchaseOrderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetPurchaseOrderPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPurchaseOrderPost$Json$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetPurchaseOrderPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetPurchaseOrderPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPurchaseOrderPost$Json(params?: {
    id?: number;
  }): Observable<PurchaseOrderDtoBaseResponse> {

    return this.apiPurchaseOrderGetPurchaseOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoBaseResponse>) => r.body as PurchaseOrderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderAddPurchaseOrderPost
   */
  static readonly ApiPurchaseOrderAddPurchaseOrderPostPath = '/api/PurchaseOrder/add-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderAddPurchaseOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderAddPurchaseOrderPost$Plain$Response(params?: {
    body?: PurchaseOrderDto
  }): Observable<StrictHttpResponse<PurchaseOrderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderAddPurchaseOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderAddPurchaseOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderAddPurchaseOrderPost$Plain(params?: {
    body?: PurchaseOrderDto
  }): Observable<PurchaseOrderDtoBaseResponse> {

    return this.apiPurchaseOrderAddPurchaseOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoBaseResponse>) => r.body as PurchaseOrderDtoBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderAddPurchaseOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderAddPurchaseOrderPost$Json$Response(params?: {
    body?: PurchaseOrderDto
  }): Observable<StrictHttpResponse<PurchaseOrderDtoBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderAddPurchaseOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderDtoBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderAddPurchaseOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderAddPurchaseOrderPost$Json(params?: {
    body?: PurchaseOrderDto
  }): Observable<PurchaseOrderDtoBaseResponse> {

    return this.apiPurchaseOrderAddPurchaseOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderDtoBaseResponse>) => r.body as PurchaseOrderDtoBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderSendPurchaseOrderEmailPost
   */
  static readonly ApiPurchaseOrderSendPurchaseOrderEmailPostPath = '/api/PurchaseOrder/send-purchase-order-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderSendPurchaseOrderEmailPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiPurchaseOrderSendPurchaseOrderEmailPost$Plain$Response(params?: {
    purchaseOrderId?: number;
    filename?: string;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderSendPurchaseOrderEmailPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('filename', params.filename, {});
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderSendPurchaseOrderEmailPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiPurchaseOrderSendPurchaseOrderEmailPost$Plain(params?: {
    purchaseOrderId?: number;
    filename?: string;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderSendPurchaseOrderEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderSendPurchaseOrderEmailPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiPurchaseOrderSendPurchaseOrderEmailPost$Json$Response(params?: {
    purchaseOrderId?: number;
    filename?: string;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderSendPurchaseOrderEmailPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('filename', params.filename, {});
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderSendPurchaseOrderEmailPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  apiPurchaseOrderSendPurchaseOrderEmailPost$Json(params?: {
    purchaseOrderId?: number;
    filename?: string;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
    body?: { 'formFile'?: Blob }
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderSendPurchaseOrderEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderSendPurchaseOrderDispatchEmailPost
   */
  static readonly ApiPurchaseOrderSendPurchaseOrderDispatchEmailPostPath = '/api/PurchaseOrder/send-purchase-order-dispatch-email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Plain$Response(params?: {
    purchaseOrderId?: number;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderSendPurchaseOrderDispatchEmailPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Plain(params?: {
    purchaseOrderId?: number;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Json$Response(params?: {
    purchaseOrderId?: number;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderSendPurchaseOrderDispatchEmailPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('emailTo', params.emailTo, {});
      rb.query('emailSubject', params.emailSubject, {});
      rb.query('emailBody', params.emailBody, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Json(params?: {
    purchaseOrderId?: number;
    emailTo?: string;
    emailSubject?: string;
    emailBody?: string;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderSendPurchaseOrderDispatchEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderUpdatePurchaseOrderPost
   */
  static readonly ApiPurchaseOrderUpdatePurchaseOrderPostPath = '/api/PurchaseOrder/update-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderUpdatePurchaseOrderPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderUpdatePurchaseOrderPost$Plain$Response(params?: {
    body?: PurchaseOrderDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderUpdatePurchaseOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderUpdatePurchaseOrderPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderUpdatePurchaseOrderPost$Plain(params?: {
    body?: PurchaseOrderDto
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderUpdatePurchaseOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderUpdatePurchaseOrderPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderUpdatePurchaseOrderPost$Json$Response(params?: {
    body?: PurchaseOrderDto
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderUpdatePurchaseOrderPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderUpdatePurchaseOrderPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPurchaseOrderUpdatePurchaseOrderPost$Json(params?: {
    body?: PurchaseOrderDto
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderUpdatePurchaseOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderToggleArchivePurchaseOrderPost
   */
  static readonly ApiPurchaseOrderToggleArchivePurchaseOrderPostPath = '/api/PurchaseOrder/toggle-archive-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderToggleArchivePurchaseOrderPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderToggleArchivePurchaseOrderPost$Plain$Response(params?: {
    purchaseOrderId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderToggleArchivePurchaseOrderPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderToggleArchivePurchaseOrderPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderToggleArchivePurchaseOrderPost$Plain(params?: {
    purchaseOrderId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderToggleArchivePurchaseOrderPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderToggleArchivePurchaseOrderPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderToggleArchivePurchaseOrderPost$Json$Response(params?: {
    purchaseOrderId?: number;
    isArchive?: boolean;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderToggleArchivePurchaseOrderPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('isArchive', params.isArchive, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderToggleArchivePurchaseOrderPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderToggleArchivePurchaseOrderPost$Json(params?: {
    purchaseOrderId?: number;
    isArchive?: boolean;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderToggleArchivePurchaseOrderPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderUpdateStatusPost
   */
  static readonly ApiPurchaseOrderUpdateStatusPostPath = '/api/PurchaseOrder/update-status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderUpdateStatusPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderUpdateStatusPost$Plain$Response(params?: {
    purchaseOrderId?: number;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    status?: PurchaseOrderStatus;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderUpdateStatusPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderUpdateStatusPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderUpdateStatusPost$Plain(params?: {
    purchaseOrderId?: number;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    status?: PurchaseOrderStatus;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderUpdateStatusPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderUpdateStatusPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderUpdateStatusPost$Json$Response(params?: {
    purchaseOrderId?: number;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    status?: PurchaseOrderStatus;
  }): Observable<StrictHttpResponse<BooleanBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderUpdateStatusPostPath, 'post');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderUpdateStatusPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderUpdateStatusPost$Json(params?: {
    purchaseOrderId?: number;

    /**
     * 0 &#x3D; Pending
     * 
     * 1 &#x3D; Confirmed
     * 
     * 2 &#x3D; Dispatched
     */
    status?: PurchaseOrderStatus;
  }): Observable<BooleanBaseResponse> {

    return this.apiPurchaseOrderUpdateStatusPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanBaseResponse>) => r.body as BooleanBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderGetItemsForPurchaseOrderGet
   */
  static readonly ApiPurchaseOrderGetItemsForPurchaseOrderGetPath = '/api/PurchaseOrder/get-items-for-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetItemsForPurchaseOrderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetItemsForPurchaseOrderGet$Plain$Response(params?: {
    purchaseOrderId?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetItemsForPurchaseOrderGetPath, 'get');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetItemsForPurchaseOrderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetItemsForPurchaseOrderGet$Plain(params?: {
    purchaseOrderId?: number;
  }): Observable<PurchaseOrderItemDtoListBaseResponse> {

    return this.apiPurchaseOrderGetItemsForPurchaseOrderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>) => r.body as PurchaseOrderItemDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetItemsForPurchaseOrderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetItemsForPurchaseOrderGet$Json$Response(params?: {
    purchaseOrderId?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetItemsForPurchaseOrderGetPath, 'get');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetItemsForPurchaseOrderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetItemsForPurchaseOrderGet$Json(params?: {
    purchaseOrderId?: number;
  }): Observable<PurchaseOrderItemDtoListBaseResponse> {

    return this.apiPurchaseOrderGetItemsForPurchaseOrderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderItemDtoListBaseResponse>) => r.body as PurchaseOrderItemDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderGetPaymentsForPurchaseOrderGet
   */
  static readonly ApiPurchaseOrderGetPaymentsForPurchaseOrderGetPath = '/api/PurchaseOrder/get-payments-for-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Plain$Response(params?: {
    purchaseOrderId?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetPaymentsForPurchaseOrderGetPath, 'get');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Plain(params?: {
    purchaseOrderId?: number;
  }): Observable<PurchaseOrderPaymentDtoListBaseResponse> {

    return this.apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>) => r.body as PurchaseOrderPaymentDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Json$Response(params?: {
    purchaseOrderId?: number;
  }): Observable<StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetPaymentsForPurchaseOrderGetPath, 'get');
    if (params) {
      rb.query('purchaseOrderId', params.purchaseOrderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Json(params?: {
    purchaseOrderId?: number;
  }): Observable<PurchaseOrderPaymentDtoListBaseResponse> {

    return this.apiPurchaseOrderGetPaymentsForPurchaseOrderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderPaymentDtoListBaseResponse>) => r.body as PurchaseOrderPaymentDtoListBaseResponse)
    );
  }

  /**
   * Path part for operation apiPurchaseOrderGetProjectsForPurchaseOrderGet
   */
  static readonly ApiPurchaseOrderGetProjectsForPurchaseOrderGetPath = '/api/PurchaseOrder/get-projects-for-purchase-order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetProjectsForPurchaseOrderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetProjectsForPurchaseOrderGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetProjectsForPurchaseOrderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetProjectsForPurchaseOrderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetProjectsForPurchaseOrderGet$Plain(params?: {
  }): Observable<PurchaseOrderBuilderProjectDtoListBaseResponse> {

    return this.apiPurchaseOrderGetProjectsForPurchaseOrderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>) => r.body as PurchaseOrderBuilderProjectDtoListBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPurchaseOrderGetProjectsForPurchaseOrderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetProjectsForPurchaseOrderGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PurchaseOrderService.ApiPurchaseOrderGetProjectsForPurchaseOrderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiPurchaseOrderGetProjectsForPurchaseOrderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPurchaseOrderGetProjectsForPurchaseOrderGet$Json(params?: {
  }): Observable<PurchaseOrderBuilderProjectDtoListBaseResponse> {

    return this.apiPurchaseOrderGetProjectsForPurchaseOrderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<PurchaseOrderBuilderProjectDtoListBaseResponse>) => r.body as PurchaseOrderBuilderProjectDtoListBaseResponse)
    );
  }

}
