/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BooleanDlmBaseResponse } from '../models/boolean-dlm-base-response';
import { LoadcellInformationResponseDlmBaseResponse } from '../models/loadcell-information-response-dlm-base-response';
import { LoadcellResponse } from '../models/loadcell-response';
import { LoadcellResponseListDlmBaseResponse } from '../models/loadcell-response-list-dlm-base-response';
import { StatusNullableDlmBaseResponse } from '../models/status-nullable-dlm-base-response';
import { TestMachine } from '../models/test-machine';

@Injectable({
  providedIn: 'root',
})
export class RepairsAndCalibrationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsLoadcellsGet
   */
  static readonly ApiRepairsAndCalibrationsLoadcellsGetPath = '/api/RepairsAndCalibrations/loadcells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsLoadcellsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellsGet$Plain$Response(params?: {
    filterText?: string;
    userId?: number;
    isDashboard?: boolean;
  }): Observable<StrictHttpResponse<LoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsLoadcellsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
      rb.query('userId', params.userId, {});
      rb.query('isDashboard', params.isDashboard, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsLoadcellsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellsGet$Plain(params?: {
    filterText?: string;
    userId?: number;
    isDashboard?: boolean;
  }): Observable<LoadcellResponseListDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsLoadcellsGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellResponseListDlmBaseResponse>) => r.body as LoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsLoadcellsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellsGet$Json$Response(params?: {
    filterText?: string;
    userId?: number;
    isDashboard?: boolean;
  }): Observable<StrictHttpResponse<LoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsLoadcellsGetPath, 'get');
    if (params) {
      rb.query('filterText', params.filterText, {});
      rb.query('userId', params.userId, {});
      rb.query('isDashboard', params.isDashboard, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsLoadcellsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellsGet$Json(params?: {
    filterText?: string;
    userId?: number;
    isDashboard?: boolean;
  }): Observable<LoadcellResponseListDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsLoadcellsGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellResponseListDlmBaseResponse>) => r.body as LoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsEditLoadcellsPost
   */
  static readonly ApiRepairsAndCalibrationsEditLoadcellsPostPath = '/api/RepairsAndCalibrations/edit-loadcells';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsEditLoadcellsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsEditLoadcellsPost$Plain$Response(params?: {
    body?: Array<LoadcellResponse>
  }): Observable<StrictHttpResponse<LoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsEditLoadcellsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsEditLoadcellsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsEditLoadcellsPost$Plain(params?: {
    body?: Array<LoadcellResponse>
  }): Observable<LoadcellResponseListDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsEditLoadcellsPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellResponseListDlmBaseResponse>) => r.body as LoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsEditLoadcellsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsEditLoadcellsPost$Json$Response(params?: {
    body?: Array<LoadcellResponse>
  }): Observable<StrictHttpResponse<LoadcellResponseListDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsEditLoadcellsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellResponseListDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsEditLoadcellsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsEditLoadcellsPost$Json(params?: {
    body?: Array<LoadcellResponse>
  }): Observable<LoadcellResponseListDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsEditLoadcellsPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellResponseListDlmBaseResponse>) => r.body as LoadcellResponseListDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsDeleteLoadcellPost
   */
  static readonly ApiRepairsAndCalibrationsDeleteLoadcellPostPath = '/api/RepairsAndCalibrations/delete-loadcell';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsDeleteLoadcellPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsDeleteLoadcellPost$Plain$Response(params?: {
    body?: LoadcellResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsDeleteLoadcellPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsDeleteLoadcellPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsDeleteLoadcellPost$Plain(params?: {
    body?: LoadcellResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsDeleteLoadcellPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsDeleteLoadcellPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsDeleteLoadcellPost$Json$Response(params?: {
    body?: LoadcellResponse
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsDeleteLoadcellPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsDeleteLoadcellPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRepairsAndCalibrationsDeleteLoadcellPost$Json(params?: {
    body?: LoadcellResponse
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsDeleteLoadcellPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsLoadcellInformationGet
   */
  static readonly ApiRepairsAndCalibrationsLoadcellInformationGetPath = '/api/RepairsAndCalibrations/loadcell-information';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsLoadcellInformationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellInformationGet$Plain$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsLoadcellInformationGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsLoadcellInformationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellInformationGet$Plain(params?: {
    loadcellId?: number;
  }): Observable<LoadcellInformationResponseDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsLoadcellInformationGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>) => r.body as LoadcellInformationResponseDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsLoadcellInformationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellInformationGet$Json$Response(params?: {
    loadcellId?: number;
  }): Observable<StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsLoadcellInformationGetPath, 'get');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsLoadcellInformationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsLoadcellInformationGet$Json(params?: {
    loadcellId?: number;
  }): Observable<LoadcellInformationResponseDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsLoadcellInformationGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<LoadcellInformationResponseDlmBaseResponse>) => r.body as LoadcellInformationResponseDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsGetStatusByFormIdGet
   */
  static readonly ApiRepairsAndCalibrationsGetStatusByFormIdGetPath = '/api/RepairsAndCalibrations/get-status-by-form-id';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsGetStatusByFormIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsGetStatusByFormIdGet$Plain$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<StatusNullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsGetStatusByFormIdGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusNullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsGetStatusByFormIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsGetStatusByFormIdGet$Plain(params?: {
    form10aId?: number;
  }): Observable<StatusNullableDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsGetStatusByFormIdGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<StatusNullableDlmBaseResponse>) => r.body as StatusNullableDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsGetStatusByFormIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsGetStatusByFormIdGet$Json$Response(params?: {
    form10aId?: number;
  }): Observable<StrictHttpResponse<StatusNullableDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsGetStatusByFormIdGetPath, 'get');
    if (params) {
      rb.query('form10aId', params.form10aId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusNullableDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsGetStatusByFormIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsGetStatusByFormIdGet$Json(params?: {
    form10aId?: number;
  }): Observable<StatusNullableDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsGetStatusByFormIdGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<StatusNullableDlmBaseResponse>) => r.body as StatusNullableDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsUpdateInvoiceAddressPost
   */
  static readonly ApiRepairsAndCalibrationsUpdateInvoiceAddressPostPath = '/api/RepairsAndCalibrations/update-invoice-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Plain$Response(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateInvoiceAddressPostPath, 'post');
    if (params) {
      rb.query('customerLocationId', params.customerLocationId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Plain(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Json$Response(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateInvoiceAddressPostPath, 'post');
    if (params) {
      rb.query('customerLocationId', params.customerLocationId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Json(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateInvoiceAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsUpdateDispatchAddressPost
   */
  static readonly ApiRepairsAndCalibrationsUpdateDispatchAddressPostPath = '/api/RepairsAndCalibrations/update-dispatch-address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateDispatchAddressPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateDispatchAddressPost$Plain$Response(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateDispatchAddressPostPath, 'post');
    if (params) {
      rb.query('customerLocationId', params.customerLocationId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateDispatchAddressPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateDispatchAddressPost$Plain(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateDispatchAddressPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateDispatchAddressPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateDispatchAddressPost$Json$Response(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateDispatchAddressPostPath, 'post');
    if (params) {
      rb.query('customerLocationId', params.customerLocationId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateDispatchAddressPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateDispatchAddressPost$Json(params?: {
    customerLocationId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateDispatchAddressPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsUpdateQuoteContactPost
   */
  static readonly ApiRepairsAndCalibrationsUpdateQuoteContactPostPath = '/api/RepairsAndCalibrations/update-quote-contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateQuoteContactPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateQuoteContactPost$Plain$Response(params?: {
    customerQuoteId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateQuoteContactPostPath, 'post');
    if (params) {
      rb.query('customerQuoteId', params.customerQuoteId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateQuoteContactPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateQuoteContactPost$Plain(params?: {
    customerQuoteId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateQuoteContactPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateQuoteContactPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateQuoteContactPost$Json$Response(params?: {
    customerQuoteId?: number;
    loadcellId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateQuoteContactPostPath, 'post');
    if (params) {
      rb.query('customerQuoteId', params.customerQuoteId, {});
      rb.query('loadcellId', params.loadcellId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateQuoteContactPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateQuoteContactPost$Json(params?: {
    customerQuoteId?: number;
    loadcellId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateQuoteContactPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsUpdateTestMachinePost
   */
  static readonly ApiRepairsAndCalibrationsUpdateTestMachinePostPath = '/api/RepairsAndCalibrations/update-test-machine';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateTestMachinePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateTestMachinePost$Plain$Response(params?: {
    loadcellId?: number;

    /**
     * 0 &#x3D; Unassigned
     * 
     * 1 &#x3D; GoAhead
     * 
     * 2 &#x3D; One
     * 
     * 3 &#x3D; Sixty
     * 
     * 4 &#x3D; OneHundred
     * 
     * 5 &#x3D; ThreeHundred
     * 
     * 6 &#x3D; Vulcan
     * 
     * 7 &#x3D; Other
     * 
     * 8 &#x3D; CalReportComplete
     */
    testMachine?: TestMachine;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateTestMachinePostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
      rb.query('testMachine', params.testMachine, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateTestMachinePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateTestMachinePost$Plain(params?: {
    loadcellId?: number;

    /**
     * 0 &#x3D; Unassigned
     * 
     * 1 &#x3D; GoAhead
     * 
     * 2 &#x3D; One
     * 
     * 3 &#x3D; Sixty
     * 
     * 4 &#x3D; OneHundred
     * 
     * 5 &#x3D; ThreeHundred
     * 
     * 6 &#x3D; Vulcan
     * 
     * 7 &#x3D; Other
     * 
     * 8 &#x3D; CalReportComplete
     */
    testMachine?: TestMachine;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateTestMachinePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateTestMachinePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateTestMachinePost$Json$Response(params?: {
    loadcellId?: number;

    /**
     * 0 &#x3D; Unassigned
     * 
     * 1 &#x3D; GoAhead
     * 
     * 2 &#x3D; One
     * 
     * 3 &#x3D; Sixty
     * 
     * 4 &#x3D; OneHundred
     * 
     * 5 &#x3D; ThreeHundred
     * 
     * 6 &#x3D; Vulcan
     * 
     * 7 &#x3D; Other
     * 
     * 8 &#x3D; CalReportComplete
     */
    testMachine?: TestMachine;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateTestMachinePostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
      rb.query('testMachine', params.testMachine, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateTestMachinePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateTestMachinePost$Json(params?: {
    loadcellId?: number;

    /**
     * 0 &#x3D; Unassigned
     * 
     * 1 &#x3D; GoAhead
     * 
     * 2 &#x3D; One
     * 
     * 3 &#x3D; Sixty
     * 
     * 4 &#x3D; OneHundred
     * 
     * 5 &#x3D; ThreeHundred
     * 
     * 6 &#x3D; Vulcan
     * 
     * 7 &#x3D; Other
     * 
     * 8 &#x3D; CalReportComplete
     */
    testMachine?: TestMachine;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateTestMachinePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * Path part for operation apiRepairsAndCalibrationsUpdateInspectedByPost
   */
  static readonly ApiRepairsAndCalibrationsUpdateInspectedByPostPath = '/api/RepairsAndCalibrations/update-inspected-by';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateInspectedByPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInspectedByPost$Plain$Response(params?: {
    loadcellId?: number;
    employeeId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateInspectedByPostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateInspectedByPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInspectedByPost$Plain(params?: {
    loadcellId?: number;
    employeeId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateInspectedByPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRepairsAndCalibrationsUpdateInspectedByPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInspectedByPost$Json$Response(params?: {
    loadcellId?: number;
    employeeId?: number;
  }): Observable<StrictHttpResponse<BooleanDlmBaseResponse>> {

    const rb = new RequestBuilder(this.rootUrl, RepairsAndCalibrationsService.ApiRepairsAndCalibrationsUpdateInspectedByPostPath, 'post');
    if (params) {
      rb.query('loadcellId', params.loadcellId, {});
      rb.query('employeeId', params.employeeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BooleanDlmBaseResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRepairsAndCalibrationsUpdateInspectedByPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRepairsAndCalibrationsUpdateInspectedByPost$Json(params?: {
    loadcellId?: number;
    employeeId?: number;
  }): Observable<BooleanDlmBaseResponse> {

    return this.apiRepairsAndCalibrationsUpdateInspectedByPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BooleanDlmBaseResponse>) => r.body as BooleanDlmBaseResponse)
    );
  }

}
